import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useFetchCnae from '../../customHooks/UseFetchCnae';
import useCnaeContext from '../../contexts/CnaeContext';
import WrapperInfoCnae from '../templates/WrapperInfoCnae';
import SEO from '../atoms/SEO';
import { validateDescription, validateTitle } from '../functions/Helpers';

const DEFAULT_VALUE = {
  cnae: {
    subclasse: '',
    _id: 0,
    atividades: [''],
    aliquota: ''
  }
};

export default function Cnae({ pageContext, location }) {
  const {
    subclasse,
    _id,
    atividades,
    aliquota
  } = pageContext.cnae || DEFAULT_VALUE;

  const { getById } = useFetchCnae();
  const { getOption } = useCnaeContext();
  const [description, setDescription] = useState('');
  const [title, setTitle] = useState('');

  function createTitle() {
    return validateTitle(aliquota, subclasse);
  }

  function createDescription() {
    return validateDescription(atividades);
  }

  useEffect(() => {
    getCnae();
  }, [pageContext]);

  async function getCnae() {
    const data = await getById(_id);
    getOption(data.cnae);
    setDescription(createDescription());
    setTitle(createTitle());
  }

  return (
    <>
      <SEO title={title} description={description} />
      <WrapperInfoCnae noFilter />
    </>
  );
}

Cnae.propTypes = {
  pageContext: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};
Cnae.defaultProps = {
  pageContext: DEFAULT_VALUE,
};
