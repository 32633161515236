import React from 'react';
import Cnae from '../components/pages/Cnae';
import { Box } from '@material-ui/core';

export default function cnae({ pageContext, location }) {
  return (
    <>
      <Box>
        <Cnae pageContext={pageContext} location={location} />
      </Box>
    </>
  );
}
