export function validateDescription(activities) {
  const description = [];
  activities.forEach(activity => {
    const descriptionLength = description.join(', ').length;
    const totalResult = descriptionLength + activity.length;
    if (totalResult <= 320) {
      description.push(activity);
    }
  });
  return description.join(', ');
}

export function validateTitle(aliquota, subclasse) {
  if (aliquota.indexOf('Sua empresa') !== -1) {
    return `CNAE ${subclasse}, não enquadra no simples nacional. ⬝ Conube`;
  } else if (aliquota.length < 1) {
    return `CNAE ${subclasse} ⬝ Conube`;
  }
  return `CNAE ${subclasse}, com a alíquota de ${aliquota} ⬝ Conube`;
}
